import { Injectable } from '@angular/core';
import { MatchModel, SportModel, TournamentModel, QuicklinkModel } from 'src/app/shared/models/sport.model';
import { Store, StoreConfig } from '@datorama/akita';
import { BetCoupon, BetCouponGlobalVariable, Dictionary } from 'clientside-coupon';
import { cloneDeep, isEqual } from 'lodash-es';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { CouponReceiptContentModel, CouponReceiptPhoneVerificationContentModel } from 'src/app/shared/models/coupon-receipt.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponSettings,
  CouponState,
  CouponUIState,
  BestSellerModel,
  DefaultCouponStake,
  ExpiredEventsModel,
  MarketMatchMap,
  OddChanges,
  FlexicutOptionModel,
} from 'src/app/shared/models/coupon.model';
import {
  BOOKED_COUPONS_KEY,
  COUPON_DATA_KEY,
  COUPON_SETTINGS_KEY,
  DEFAULT_COUPON_STAKE_KEY,
  EXPIRED_EVENTS_KEY,
  LAST_PLACED_COUPON_KEY,
  ODD_CHANGES_KEY,
  BOOKING_CODE_KEY,
} from 'src/app/shared/utils/local-storage-keys';
import { FlexicutComputationResponse } from '@kingmakers-tech/flexicut-csc';
import { map } from 'rxjs/operators';

const createInitialState = (): CouponState => ({
  betslipScrollTop: undefined,
  bookedBetData: undefined,
  bookedCoupons: undefined,
  correctScoreOddsMatrix: undefined,
  couponData: undefined,
  couponInitialized: false,
  couponReceiptContent: undefined,
  couponReceiptPhoneVerificationContent: undefined,
  couponSettings: undefined,
  defaultCouponStake: undefined,
  editCouponData: undefined,
  expiredEvents: undefined,
  flexicutSelectedOption: undefined,
  flexicutResponse: undefined,
  globalVariables: undefined,
  groupingsTabSelected: undefined,
  bestSellerCoupons: [],
  emptyBetslipQuicklinks: [],
  invalidFreebetSelections: [],
  invalidFlexicutSelections: [],
  isFlexicutServiceInitialised: false,
  isLastPlacedCouponInEvaluation: false,
  lastPlacedCoupon: undefined,
  lastPlacedCouponCode: '',
  marketExceptions: undefined,
  oddChanges: [],
  previousPagePath: '/',
  selectionMarketMatches: undefined,
  selections: undefined,
  groupedSelections: undefined,
  ui: {
    showCoupon: false,
    showQuickCoupon: false,
    couponForEdit: {
      matchId: undefined,
      marketTypeId: undefined,
    },
  },
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'coupon' })
export class CouponStore extends Store<CouponState> {
  private readonly globalVariablesKey: string = 'sportsbook.globalVariables';
  private readonly marketExceptionsKey: string = 'sportsbook.marketExceptions';
  private readonly correctScoreOddsMatrixKey: string = 'sportsbook.correctScoreOddsMatrix';

  constructor(
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly localStorage: LocalStorageService,
    private readonly sessionStorage: SessionStorageService
  ) {
    super(createInitialState());

    this.localStorage
      .observe(COUPON_DATA_KEY)
      .pipe(
        map((couponData: BetCoupon) => {
          if (couponData?.Odds.length) {
            const couponDataOddIds = couponData?.Odds.length ? couponData.Odds.map(odd => odd.SelectionId).sort() : [];
            const oddChangesOddIds = this._value().oddChanges.length
              ? this._value()
                  .oddChanges.map(odd => odd.oddId)
                  .sort()
              : [];

            if (
              !(
                couponDataOddIds.length === oddChangesOddIds.length &&
                couponDataOddIds.every((value, index) => value === oddChangesOddIds[index])
              )
            ) {
              // OddsChanges and CSC CouponData are not in sync.
              const oddChanges = couponData
                ? couponData.Odds.map(
                    odd =>
                      new OddChanges({
                        oddId: odd.SelectionId,
                        initialOddValue: odd.OddValue,
                        latestOddValue: odd.OddValue,
                        valueChanged: false,
                      })
                  )
                : [];

              this.update({ oddChanges });
              this.localStorage.store(ODD_CHANGES_KEY, oddChanges);
            }
          }

          // Always return new couponData
          return couponData;
        })
      )
      .subscribe(couponData => {
        this.update({ couponData });
      });

    this.updateCouponData(this.localStorage.retrieve(COUPON_DATA_KEY));

    this.localStorage.observe(LAST_PLACED_COUPON_KEY).subscribe(lastPlacedCoupon => {
      this.update({ lastPlacedCoupon });
    });
    this.updateLastPlacedCoupon(this.localStorage.retrieve(LAST_PLACED_COUPON_KEY));

    this.localStorage.observe(COUPON_SETTINGS_KEY).subscribe(couponSettings => {
      this.update({ couponSettings });
    });
    this.updateCouponSettings(this.localStorage.retrieve(COUPON_SETTINGS_KEY));

    this.localStorage.observe(DEFAULT_COUPON_STAKE_KEY).subscribe(defaultCouponStake => {
      this.update({ defaultCouponStake });
    });
    this.updateDefaultCouponStake(this.localStorage.retrieve(DEFAULT_COUPON_STAKE_KEY));

    this.localStorage.observe(ODD_CHANGES_KEY).subscribe(oddChanges => {
      this.update({ oddChanges: oddChanges || [] });
    });
    this.updateOddChanges(this.localStorage.retrieve(ODD_CHANGES_KEY));

    this.localStorage.observe(BOOKED_COUPONS_KEY).subscribe(bookedCoupons => {
      this.update({ bookedCoupons });
    });
    this.updateBookedCoupons(this.localStorage.retrieve(BOOKED_COUPONS_KEY));

    this.localStorage.observe(EXPIRED_EVENTS_KEY).subscribe(expiredEvents => {
      this.update({ expiredEvents });
    });
    this.updateExpiredEvents(this.localStorage.retrieve(EXPIRED_EVENTS_KEY));

    this.updateCorrectScoreOddsMatrix(this.sessionStorage.retrieve(this.correctScoreOddsMatrixKey));
  }

  updateCouponData(couponData: BetCoupon): void {
    if (!couponData) {
      this.clearCouponData();
      return;
    }

    this.localStorage.store(COUPON_DATA_KEY, couponData);

    if (this.localStorage.retrieve(COUPON_SETTINGS_KEY) === null) {
      const couponSettings = new CouponSettings();
      this.updateCouponSettings(couponSettings);
    }
  }

  updateLastPlacedCoupon(data: BetCoupon): void {
    this.localStorage.store(LAST_PLACED_COUPON_KEY, data);
  }

  updateCouponInitialized(couponInitialized: boolean): void {
    this.update({ couponInitialized });
  }

  updateCouponSettings(couponSettings: CouponSettings): void {
    if (!couponSettings) {
      this.clearCouponSettings();
      return;
    }

    /**
     * TODO
     * This is a temporary fix of Maximum call stack exceeded issue.
     * @see https://kingmakers.atlassian.net/browse/BET-459
     * Jonathan Micallef please feel free to make a proper one later on.
     */
    const oldCouponSettings = this.localStorage.retrieve(COUPON_SETTINGS_KEY);

    if (!isEqual(couponSettings, oldCouponSettings)) {
      this.localStorage.store(COUPON_SETTINGS_KEY, couponSettings);
    } else {
      this.update({ couponSettings });
    }
  }

  updateCouponSetting(couponSettings: Partial<CouponState['couponSettings']>): void {
    this.update(state => ({
      couponSettings: {
        ...state.couponSettings,
        ...couponSettings,
      },
    }));

    this.localStorage.store(COUPON_SETTINGS_KEY, {
      ...this.localStorage.retrieve(COUPON_SETTINGS_KEY),
      ...couponSettings,
    });
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    if (!defaultCouponStake) {
      this.clearDefaultCouponStake();
      return;
    }

    this.localStorage.store(DEFAULT_COUPON_STAKE_KEY, defaultCouponStake);
  }

  updateOddChanges(oddChanges: OddChanges[]): void {
    if (!oddChanges) {
      this.clearOddChanges();
      return;
    }

    this.localStorage.store(ODD_CHANGES_KEY, oddChanges);
  }

  addToOddChanges(odds: { oddId: number; oddValue: number }[]): void {
    this.update(state => {
      const oddChanges = [
        ...(state.oddChanges || []),
        ...odds.map(
          odd =>
            new OddChanges({
              oddId: odd.oddId,
              initialOddValue: odd.oddValue,
              latestOddValue: odd.oddValue,
              valueChanged: false,
            })
        ),
      ];

      this.localStorage.store(ODD_CHANGES_KEY, oddChanges);
    });
  }

  removeFromOddChanges(oddId: number): void {
    this.update(state => {
      const oddChanges = state.oddChanges?.filter(o => o.oddId !== oddId);
      this.localStorage.store(ODD_CHANGES_KEY, oddChanges);
      return { oddChanges };
    });
  }

  updateOddChange(oddId: number, newOddValue: number): void {
    this.update(state => {
      const oddChangesCopy: OddChanges[] = cloneDeep(state.oddChanges);

      oddChangesCopy.forEach(odd => {
        if (odd.oddId === oddId) {
          odd.valueChanged = true;
          odd.latestOddValue = newOddValue;
        }
      });

      this.localStorage.store(ODD_CHANGES_KEY, oddChangesCopy);
      return { oddChanges: oddChangesCopy };
    });
  }

  updateExpiredEvents(expiredEvents: ExpiredEventsModel): void {
    if (!expiredEvents) {
      this.clearExpiredEvents();
      return;
    }

    this.localStorage.store(EXPIRED_EVENTS_KEY, expiredEvents);
  }

  updateBestSellers(bestSellerCoupons: BestSellerModel[]): void {
    this.update({ bestSellerCoupons });
  }

  updateEmptyBetslipQuicklinks(emptyBetslipQuicklinks: QuicklinkModel[]): void {
    this.update({ emptyBetslipQuicklinks });
  }

  updateInvalidFreebetSelections(invalidFreebetSelections: number[]): void {
    this.update({ invalidFreebetSelections });
  }

  updateInvalidFlexicutSelections(invalidFlexicutSelections: number[]): void {
    this.update({ invalidFlexicutSelections });
  }

  updateBookedCoupons(bookedCoupons: BookedCoupon[]): void {
    if (!bookedCoupons) {
      this.clearBookedCoupons();
      return;
    }

    this.localStorage.store(BOOKED_COUPONS_KEY, bookedCoupons);
  }

  updateBookedCoupon(bookedCoupon: BookedCoupon): void {
    const bookedCoupons: BookedCoupon[] = [];
    const storedBookedCoupons = this.localStorage.retrieve(BOOKED_COUPONS_KEY);
    const storedBookedCouponsCopy: BookedCoupon[] = cloneDeep(storedBookedCoupons);

    if (!storedBookedCouponsCopy) {
      bookedCoupons.push(bookedCoupon);
      this.localStorage.store(BOOKED_COUPONS_KEY, bookedCoupons);
    } else {
      storedBookedCouponsCopy.push(bookedCoupon);
      this.localStorage.store(BOOKED_COUPONS_KEY, storedBookedCouponsCopy);
    }
  }

  updateGlobalVariables(globalVariables: BetCouponGlobalVariable): void {
    if (!globalVariables) {
      this.clearGlobalVariables();
      return;
    }

    this.update({ globalVariables });
    this.sessionStorage.store(this.globalVariablesKey, globalVariables);
  }

  updateMarketExceptions(marketExceptions: Dictionary<number, number[]>): void {
    if (!marketExceptions) {
      this.clearMarketExceptions();
      return;
    }

    this.update({ marketExceptions });
    this.sessionStorage.store(this.marketExceptionsKey, marketExceptions);
  }

  updateCorrectScoreOddsMatrix(correctScoreOddsMatrix: any): void {
    if (!correctScoreOddsMatrix) {
      this.clearCorrectScoreOddsMatrix();
      return;
    }

    this.update({ correctScoreOddsMatrix });
    this.sessionStorage.store(this.correctScoreOddsMatrixKey, correctScoreOddsMatrix);
  }

  updateGroupingTab(groupingsTabSelected: CouponGroupingType): void {
    if (!groupingsTabSelected) {
      this.clearGroupingTab();
      return;
    }

    this.update({ groupingsTabSelected });
  }

  updateUI(ui: Partial<CouponUIState>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateEditCouponData(editCouponData: MatchModel): void {
    this.update({ editCouponData });
  }

  updateOpenedForEditCoupon(matchId: number, marketTypeId: number): void {
    this.updateUI({
      couponForEdit: {
        matchId,
        marketTypeId,
      },
    });
  }

  updateSelections(selections: TournamentModel[]): void {
    this.update({ selections });
  }

  updateGroupedSelections(groupedSelections: SportModel[]): void {
    this.update({ groupedSelections });
  }

  updateMarketMatches(selectionMarketMatches: MarketMatchMap[]): void {
    this.update({ selectionMarketMatches });
  }

  clearCouponData(): void {
    this.update({
      invalidFreebetSelections: [],
      invalidFlexicutSelections: [],
    });
    this.localStorage.clear(COUPON_DATA_KEY);
    this.localStorage.clear(BOOKING_CODE_KEY);
    this.clearOddChanges();
    this.clearExpiredEvents();
    this.clearGroupingTab();

    // Reset Acc Bonus User preference on coupon clear
    this.accumulatorBonusStore.update({
      dismissedAccumulatorBonusOddsValuePopup: false,
    });
  }

  clearCouponSettings(): void {
    this.localStorage.clear(COUPON_SETTINGS_KEY);
  }

  clearDefaultCouponStake(): void {
    this.localStorage.clear(DEFAULT_COUPON_STAKE_KEY);
  }

  clearOddChanges(): void {
    this.localStorage.clear(ODD_CHANGES_KEY);
  }

  clearExpiredEvents(): void {
    this.localStorage.clear(EXPIRED_EVENTS_KEY);
  }

  clearBookedCoupons(): void {
    this.localStorage.clear(BOOKED_COUPONS_KEY);
  }

  removeBookedCoupon(couponCode: string): void {
    const storedBookedCoupons = this.localStorage.retrieve(BOOKED_COUPONS_KEY);
    let storedBookedCouponsCopy: BookedCoupon[] = cloneDeep(storedBookedCoupons);

    if (!storedBookedCouponsCopy) {
      this.clearBookedCoupons();
    } else {
      storedBookedCouponsCopy = storedBookedCouponsCopy.filter(o => o.couponCode !== couponCode);

      if (storedBookedCouponsCopy.length > 0) {
        this.localStorage.store(BOOKED_COUPONS_KEY, storedBookedCouponsCopy);
      } else {
        this.clearBookedCoupons();
      }
    }
  }

  clearGlobalVariables(): void {
    this.update({ globalVariables: undefined });
    this.sessionStorage.clear(this.globalVariablesKey);
  }

  clearMarketExceptions(): void {
    this.update({ marketExceptions: undefined });
    this.sessionStorage.clear(this.marketExceptionsKey);
  }

  clearCorrectScoreOddsMatrix(): void {
    this.update({ correctScoreOddsMatrix: undefined });
    this.sessionStorage.clear(this.correctScoreOddsMatrixKey);
  }

  clearGroupingTab(): void {
    this.update({ groupingsTabSelected: undefined });
  }

  updateCouponReceiptContent(couponReceiptContent: CouponReceiptContentModel): void {
    this.update({ couponReceiptContent });
  }

  updateCouponReceiptPhoneVerificationContent(couponReceiptPhoneVerificationContent: CouponReceiptPhoneVerificationContentModel): void {
    this.update({ couponReceiptPhoneVerificationContent });
  }

  updateBookedBetData(bookedBetData: BookBetModel): void {
    this.update({ bookedBetData });
  }

  clearBookedBetData(): void {
    this.update({ bookedBetData: undefined });
  }

  updatePreviousPage(previousPagePath: string): void {
    this.update({ previousPagePath });
  }

  updateFlexicutServiceInitialised(initialised: boolean): void {
    this.update({
      isFlexicutServiceInitialised: initialised,
    });
  }

  updateFlexicutOdds(flexicutResponse: FlexicutComputationResponse): void {
    this.update({
      flexicutResponse,
    });
  }

  updateFlexicutSelectedOption(flexicutSelectedOption: FlexicutOptionModel): void {
    this.update({ flexicutSelectedOption });
  }
}
